<template>
<div>
  <div class="md-sl__playerBody stu_r">
    <div class="md-sl__wave_body md-record_body">
      <div class="md-sl__wave_text">
        <b v-if="hidePlayOtionplay">RECORDING WILL STARTING IN <span class="md-sl__porpule">{{this.preparation_time}} SEC</span></b>
        <b v-if="this.hidePlayOtion">RECORDING <span style="color:#00c500"> START</span></b>
        <b v-if="null">RECORDING <span class="md-sl__porpule">COMPLETED</span></b>
     </div>
      <div id="record__visual">  
        <div class="md-pre__wafe" v-if="this.HideImg">
          <img src="../../../assets/images/wave.png"/>
        </div>
        <span class="record__timer_dt"> / 00:{{answer_time}}sec</span>
        <av-media class="md-sl__wave"
          type="frequ" 
          ref-link="player"
          :media="media" 
          line-color="#762cb5" 
          :canv-width="560" 
          :fft-size="8192"
          :canv-height="120"
          :line-width="0.05"
          :frequ-lnum="150"
        />  
      </div>
      <div class="md-record__section" @click="playRecord()" :id="this.case_item.id+1">
        <audio-recorder class="md-recording_only" v-if="case_item.question_type.title !='Answer Short Question' && case_item.question_type.title !='Repeat Sentence'"
          :time="(22 + answer_time)/100"
          :before-recording="callback"
          :after-recording="callback"
          :pause-recording="callback"
          :select-record="callback"
          :before-upload="callback"
          :successful-upload="callback"
          :failed-upload="callback"
          :show-download-button="false"
          :show-upload-button="showUploadButton"
        />
        <audio-recorder class="md-recording_only" v-if="case_item.question_type.title =='Answer Short Question' || case_item.question_type.title =='Repeat Sentence'"
          :time="(5 + answer_time)/100"
          :before-recording="callback"
          :after-recording="callback"
          :pause-recording="callback"
          :select-record="callback"
          :before-upload="callback"
          :successful-upload="callback"
          :failed-upload="callback"
          :show-download-button="false"
          :show-upload-button="showUploadButton"
        />
      </div>
      <div class="md-recording_only md-audio_st">
          <audio :id="this.case_item.id" controls></audio>
      </div>
      
    </div>
   </div>
</div>
</template>
<script>

export default {
  name: 'av-media-demo',
  props: {
      pteRecord: {
          type: String
      },
      answer_time:{
        default: true
      },
      case_item:{
        default: true
      },
      preparation_time:{
       type: Number
      },
      calls: {
        type: Function
      }
  },
  data () {
    return {
      record: true,
      fileName: "",
      src: "",
      // x: 5,
      totalTime: 0.16,
      hidePlayOtion: '',
      hidePlayOtionplay: true,
      HideImg: true,
      media: null,
      showUploadButton: false,
      datas: ''
    }
  },
  components:{
  
  },
  
  mounted () {
    const audio = this.$refs.player
    const constraints = {
      audio: true,
      video: false
    }
    navigator.mediaDevices.getUserMedia(constraints)
      .then(media => {
        this.media = media
        audio.srcObject = media
    });
    this.countDownTimer();
    // if(case_item.id = case_item.id){
    //   clearTimeout(this.countDownTimer());
    // }
  },
  methods: {
    callback(data) { 
      setTimeout(() => {
            document.getElementById(this.case_item.id).setAttribute('src',data.url);
      }, 100);
      this.$emit('convertMp3ToWav',data.url)
    },
    playRecord(){
       this.HideImg = false;
    },
    countDownTimer() {
        if(this.preparation_time > 0) {
            setTimeout(() => {
                this.preparation_time -= 1;
                this.countDownTimer();  
            }, 1000);
            
        }
      if(this.preparation_time == 0 ){
        this.hidePlayOtion = true;
        if(this.case_item.question_type.is_beep == 1){
          var audio = new Audio('https://kazi-blubird.sfo2.digitaloceanspaces.com/apical/speaking/audio/beep-03.mp3');
          audio.play();
        }
        document.getElementById(this.case_item.id+1).style.display = "block"
      } else {
          document.getElementById(this.case_item.id+1).style.display = "none"
      }
      if(this.hidePlayOtion == true){
        this.hidePlayOtionplay = false
      }
    },
  },
  calls(){
      console.log("data")
  },
  // created(){
  //   this.countDownTimer();
  // },
  computed: {
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ar-recorder{
  display: none;
}
.md-sl__wave{
    border: 1px solid #bebebe;
    border-radius: 4px;
    background: #fff;
}
/* #record_control{
  display: none;
} */
.md-sl__wave_body{
    background: #bcbcbc45;
    height: 136px;
    margin-left: 20px;
    padding: 10px;
    margin-bottom: 70px;
    border-radius: 4px ;
}
.record__timer_dt{
  position: absolute;
  right: 5px;
  top: 7px;
  color: #3c3c3ca6;
  font-size: 14px;
  font-weight: 600;
}
/* #record_control{
  display: none;
}
#record_control_1{
  display: block;
} */

</style>