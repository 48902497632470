<template>
    <div>
        <div>
            <div v-if="case_item.title != null">
                <md-content 
                   v-html="case_item.title" 
                   class="md-sl__font_600 md-sl__x1 mb-4 md-content__p1">
                </md-content>
            </div>
            <div v-if="case_item.prompt != null" class="md-question__radio md-sl__font_600 pb-4">
               <md-content 
                    v-html="case_item.prompt" 
                    class="md-sl__font_600 md-sl__x1 mb-4 md-sl__content_color md-content__p1">
                </md-content>
            </div>
        </div>
        <div class="br-4" >
           <div class="md-question__radio md-sl__font_600">
                <TextField 
                   @input="input"
                />
            </div>
        </div>
    </div>
</template>
<script>
import TextField from "./textFieldBox.vue";
export default {
    name: 'Writing',
    components:{
      TextField
    },
    props: {
        case_item: {
          default: () => true
        }
    },
    data() {
      return {
        title: '',
        prompt: '',
      }
    },
    methods:{
        input(value){
            console.log(value)
            this.search_contents = value ;
            this.$root.answer = value ;
        },
    },
}
</script>
