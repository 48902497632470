<template>
  <div>
      <div id="loader" class="flex align-center justify-center  md-layout-column md-sl__br">
          <div class="">
            <div class="loader pb-2"></div> 
            <span class="pt-2">
              AI Evaluation is going on
            </span>
          </div>
      </div>
    <md-dialog :md-active.sync="showDialog" v-if="showDialog = true" id="modal__ptePractice" class="md-sl__slide">
      <form @submit.prevent="Handlesubmit" class="p-6 pr-10 overflow-y">
      <div class="page-container md-layout-column md-sl__br" :ref="index" v-for="(case_item, index) in this.question" :key="case_item.id" v-show="current_slide_number === index" :class="{'is-active-slide': current_slide_number === index}">
        <!-- slide question header start-->
        
        <div class="md-sl__h1">
          <md-toolbar class="md-sl__header" >
            <div class="md-sl__slide-header__left">
              <Dialog/>
              <div>
                <h2>{{case_item.question_type.title}}</h2>
                <p>QUESTION ID <span style="color:#762cb5">{{case_item.q_id}}</span></p>
              </div>
            </div>
            <div class="md-toolbar-section-end">
              <md-button @click="OpenStudyGuide()">STUDY GUIDE</md-button>
              <div class="md-sl__start__body">
                <md-icon style="color:#772fb6">star</md-icon>
                <md-icon style="color:#772fb6">star</md-icon>
                <md-icon style="color:#772fb6">star</md-icon>
                <md-icon style="color:#772fb6">star</md-icon>
                <md-icon style="color:#9c97975e">star</md-icon>
              </div>
              <div class="md-sl__start__body">
                <md-icon style="color:#772fb6;">bookmark</md-icon> 
              </div>
            </div>
          </md-toolbar>
          <md-toolbar class="md-sl__sub_text md-sl__r1">
            <div class="md-sl__slide-header__sub">
              <p class="md-sl__porpule md-sl__font_600"># {{case_item.index}}</p>
              <md-button class="back-blue">New</md-button>
              <md-button class="back-green">PTE practice</md-button>
              <md-button class="back-orange">Exam Q</md-button>
            </div>
            <div class="md-toolbar-section-end md-sl__section-end__sub">
              <md-button class="md-sl__timer">00 : 05 : 29</md-button>
              <md-button>Attemp <span class="md-sl__porpule">1</span></md-button>
            </div>
          </md-toolbar>
        </div>
        <!-- slide question header end-->

        
        <!-- question content body start-->
        <div>
          <div v-if="case_item.question_type.section == 'SPEAKING'">
            <md-content class="md-sl__font_600 md-sl__x1 mb-4" v-html="case_item.title">
            </md-content>
            <md-content v-html="case_item.prompt" v-if="case_item.prompt != null" class="md-sl__font_600 md-sl__x1 mb-4 md-sl__content_color md-content__p1">
            </md-content>
            <md-content v-if="case_item.question_type.title == 'Answer Short Question'" class="md-sl__font_600 md-sl__x1 mb-4 md-sl__q_color md-content__p1 md-sl__porpule">
              {{case_item.question_type.title}}  
            </md-content>
            <md-content v-if="case_item.question_type.title =='Describe Image'" class="md-sl__font_600 md-sl__x1 mb-4 md-content__p1 md-sl__porpule md-image__section">
              <img :src="filepath"/>
            </md-content>
            <md-content v-if="case_item.question_type.title =='Answer Short Question' || case_item.question_type.title =='Repeat Sentence' || case_item.question_type.title == 'Retell Lecture'">
              <Audio :src="filepath" :case_item="case_item"/>
            </md-content>
            <md-content v-if="case_item.question_type.section =='SPEAKING'" class="md-sl__font_600 md-sl__x1 md-image__section">
              <PrograssBar :preparation_time="case_item.preparation_time" @click="resetValue"/>
            </md-content>
            <md-content v-if="case_item.question_type.title == 'Describe Image' || case_item.question_type.title =='Repeat Sentence' ||  case_item.question_type.title =='Answer Short Question' || case_item.question_type.title =='Read Aloud' || case_item.question_type.title =='Retell Lecture'">
              <Record @convertMp3ToWav="convertMp3ToWav($event, case_item.transcript)" :answer_time="case_item.answer_time" :case_item="case_item" :preparation_time="case_item.preparation_time" :calls="resetValue"/>
            </md-content>
              {{displayText}}
            <md-content  class="displayText md-content md-sl__font_600 md-sl__x1 mb-4 md-theme-default" ></md-content>
          </div>
          <md-content v-if="case_item.question_type.section == 'READING'" class="md-sl__x1 mb-6">
            <Reading :case_item="case_item"/>
          </md-content>
          <md-content v-if="case_item.question_type.section == 'LISTENING'" class="mb-6">
            <Listening :case_item="case_item" :transciptEvent="transciptEvent" :activevalid="activevalid" :path="filepath"/>
          </md-content>
           <md-content v-if="case_item.question_type.section == 'WRITING'" class="mb-6">
            <Writing :case_item="case_item"/>
          </md-content>
        </div>
        <!-- question content body end-->

        <!-- bottom section start-->
        <div class="md-layout mb-3">
          <div class="flex">
            <div class="md-layout-item">
              <md-button class="md-sl__font_600 md-sl__count">{{index+1}} of {{totalquestion}}</md-button>
            </div>
            <div class="md-layout-item" v-if="case_item.question_type.section == 'LISTENING'">
              <md-button  class="md-sl__font_600 md-sl__white back-orange" @click="HandleTranscript()">Transcript</md-button>
            </div>
          </div>
          <div class="md-layout-item" v-if="layoutMeta == 'student'">
            <md-button  class="md-sl__font_600 md-sl__white back-blue" @click="Handlesubmit(case_item)">SUBMIT</md-button>
          </div>
          <div class="md-layout-item md-sl__next_body">
            <span class="md-sl__button_n_body">
              <span>
                 <md-button class="md-sl__white back-blue" @click="prevSlide(index)">
                  <span class="md-sl__pre" >
                    <ArrowIcon/>
                  </span>
                    PREVIOUS
                </md-button>
              </span>
              <span  v-if="case_item.length-1!=current_slide_number">
                <md-button class="md-sl__white back-blue" @click="nextSlide(index)">
                    NEXT
                  <span class="md-sl__next">
                    <ArrowIcon/>
                  </span>
                </md-button>
              </span>
            </span>
          </div>
        </div>
        <!-- bottom section end-->
        <StudyGuideDetails :question="case_item" :showStudyGuideDialog.sync="showMediaDialog"/>
      </div>
      </form>
          <!-- drawer section start-->
          <md-drawer class="md-right" :md-active.sync="showSidepanel" id="style-bar">
            <div class="md-drawer__body md-sl__drawer__body">
              <div class="button">
                <span class="arrow__group" @click="showSidepanels">
                  <ArrowIcon/>
                </span>
              </div>
              <md-toolbar class="md-transparent" md-elevation="0">
                <h2 class="md-sl__drawer_header__text">{{this.pteDetails.type}}<span>{{this.pteDetails.title}}</span></h2>
              </md-toolbar>
              <md-list @click="showSidepanel = false">
                <md-list-item @click="ActiveSlide(index)" v-for="(case_item, index) in this.question" :key="index">
                  <span class="md-list-item-text md__active">
                    <md-icon>check_circle</md-icon>
                    <span>{{case_item.q_id}}</span>{{case_item.index}}
                  </span>
                  <span class="md-teken_text">TAKEN</span>
                </md-list-item>                                                                  
              </md-list>
            </div>
          </md-drawer>
          <!-- drawer section end-->
    </md-dialog>
  </div>
</template>

<script>
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'
import Audio from './audio.vue'
import Record from './record.vue'
import PrograssBar from './prograssBar.vue'
import Dialog from './PteDialog.vue'
import ArrowIcon from '../../../assets/svg/arrowIcon.svg';
import { mapActions,mapGetters,mapMutations} from 'vuex';
import { putObject } from "@/store/upload";
import {SpeechToText} from "@/store/speech-to-text";
import toWav from "audiobuffer-to-wav";
import Reading from "./item/reading.vue";
import Listening from "./item/listening.vue";
import Writing from "./item/writing.vue";
import xhr from "xhr";
import {TextGearsAPI,Keys} from "@/store/textgears";
import StudyGuideDetails from "@/components/molecule/question-and-test-management/material/StudyGuide/DetailsView";
import axios from 'axios';
  export default {
    name: 'DialogCustom',
    components:{
      ArrowIcon,
      Audio,
      Record,
      PrograssBar,
      Dialog,
      Reading,
      Listening,
      Writing,
      StudyGuideDetails
    },
    data() {
      return {
        showMediaDialog: false,
        layoutMeta: 'student',
        transciptEvent:false,
        filepath: '',
        activevalid: false,
        section: '',
        id: '',
        totalquestion: '',
        // disabled: true,
        question: [],
        current_slide_number: 0,
        showDialog: true,
        showSidepanel: false,
        radio: 'accent',
        pteDetails: [],
        countDown : 10,
        displayText: '',
        evaluationDetails: [],
        PronunciationAssessment: '',
        evaluation_detail: [],
        evaluation_details: [],
        file_path: '',
        gen: '',
        Slideindex: 0,  
        incorrect_order: [], 
        textgearData: false,  
        form: {
          exam_enroll_id: null,
          question_test_id: null,
          question_id: '',
          organization_id: 'hb53361b-06bf-495b-bddf-626fc3216310',
          test_id: '',
          content_percentage: '',
          form_percentage: null,
          grammar_percentage: null,
          fluency_percentage: '',
          pronunciation_percentage: '',
          file_path: '',
          spelling_percentage: null,
          discourse_percentage: null,
          vocabulary_percentage: null,
          development_structure_coherence: null,
          general_linguistics: null,
          answer_sheet_details: '',
          answer_options: [], 
          incorrect_order: [],
          answer : '',
         
        }
      }
    },
    
    computed: {
      type() {
        return this.$route.query.type
      },
      ...mapGetters({
        //getPTEPracticeQuestionDetails: "testCreation/getPTEPracticeQuestionDetails",
      }),
    },
    methods:{
      ...mapMutations({
      setShowSnackbar: "setShowSnackbar",
      }),
      ...mapActions({
        SubmitactResultList: 'result/actSubmitactResultList',
        actTestDetails: "testCreation/actTestDetails",
      }),
      OpenStudyGuide(){
        this.showMediaDialog = true
      },
      ActiveSlide(index){
        this.current_slide_number = index;
        this.filepath = this.pteDetails.questions[index].file_path;
        //this.EventTranscript();
        localStorage.setItem('Slideindex', index)
        console.log(index)
      },
      BacktoQuestion(index){
            console.log("else",index)
      },
      nextSlide(index){
       
        this.activevalid = true
        if(this.pteDetails.questions.length-1!=this.current_slide_number){
          this.current_slide_number++;
          console.log(index+1)
          localStorage.setItem('Slideindex', index+1)
          this.filepath = this.pteDetails.questions[index+1].file_path 
        } else {
          this.current_slide_number;
        }
        this.TranscriptEvent(); 
      },
      resetValue(){
          this.nextSlide()
      },
      prevSlide(index){
        
        // this.filepath = case_item.file_path;
        //console.log(case_item)
        if(this.current_slide_number!=0){
          this.current_slide_number--;
          console.log(index-1)
          localStorage.setItem('Slideindex', index-1)
          this.filepath = this.pteDetails.questions[index-1].file_path;
        } else {
          this.current_slide_number;
        }
        this.TranscriptEvent();
      },
      showSidepanels(){
        this.showSidepanel = !this.showSidepanel
      },
      HandleTranscript(){
       this.transciptEvent = !this.transciptEvent
      },
      TranscriptEvent(){
        if(this.transciptEvent == true){
          this.transciptEvent = false
        } 
      },
      Handlesubmit(case_item){
        const self = this;
        if(case_item.question_type.short_title != "SST"){
          if(case_item.question_type.section == "READING" || case_item.question_type.section == "LISTENING") {
            self.form.answer_sheet_details = null  
            self.form.user_id = localStorage.getItem('user_id');
            self.form.test_id = self.test_id;
            self.form.question_id = case_item.id;
            self.form.answer_options = this.$root.answer_options;
            if(this.$root.incorrect_order != null){
            self.form.incorrect_order = this.$root.incorrect_order
            }
            self.form.answer_sheet_details = {
              evaluation_details : null, 
              content: null,
              form: null,
              grammar: null,
              fluency: null,
              pronunciation: null,
              spelling: null,
              discourse: null,
              vocabulary: null,
              matched_keywords: null,
              keyword_out_of_order: null,
              word_count: null,
              line_count: null,
              insertion_count: null,
              omission_count: null,
              replacement_count:null,
              pair_match_count:null,
              correct_answer_count:null,
              wrong_answer_count:null,
              development_structure_coherence:null,
              general_linguistics : null,
              question_option_id : null,
              question_hints_id : null,
              answer : this.$root.answer,
              comment : ""
            }   
            console.log(self.test_id,case_item.id,self.form.user_id)
            self.SubmitactResultList(self.form)
            .then(response => {
              response.answer_sheet_id
              localStorage.setItem('answer_sheet_id', response.answer_sheet_id);
              localStorage.setItem('answer_bottom_hide', true);
              self.setShowSnackbar(response.message);
              setTimeout(() => 
                self.$router.push({
                  name: 'student.evaluation',
                }),1000)
            }).catch(error => {
              console.log('error',error)
              self.setShowSnackbar(error);
            })
          }
        }
        if(case_item.question_type.section == "WRITING" || case_item.question_type.short_title == "SST" || self.textgearData) {
          self.textGear()
          setTimeout(() => {
          self.form.answer_sheet_details = null  
          self.form.user_id = localStorage.getItem('user_id');
          self.form.test_id = self.test_id;
          self.form.question_id = case_item.id;
          if(case_item.question_type.short_title == "ES"){
            self.form.answer_paragraphs = this.$root.answer_paragraphs
          }
          if(case_item.question_type.short_title == "SST"){
            self.form.no_punctuation = this.$root.no_punctuation,
            self.form.only_capital_letters = this.$root.only_capital_letters
          }
          console.log('self.evaluation_detail',this.evaluation_detail)
          self.form.answer_sheet_details = {
            evaluation_details : self.evaluation_detail, 
            content: null,
            form: null,
            grammar: null,
            fluency: null,
            pronunciation: null,
            spelling: null,
            discourse: null,
            vocabulary: null,
            matched_keywords: null,
            keyword_out_of_order: null,
            word_count: this.$root.wordCounter,
            line_count: this.$root.lineCount,
            insertion_count: null,
            omission_count: null,
            replacement_count:null,
            pair_match_count:null,
            correct_answer_count:null,
            wrong_answer_count:null,
            development_structure_coherence:null,
            general_linguistics : null,
            question_option_id : null,
            question_hints_id : null,
            answer : [this.$root.answer],
            comment : "",
          }   
          console.log(self.test_id,case_item.id,self.form.user_id)
          self.SubmitactResultList(self.form)
          .then(response => {
            response.answer_sheet_id
            localStorage.setItem('answer_sheet_id', response.answer_sheet_id);
            localStorage.setItem('answer_bottom_hide', true);
            self.setShowSnackbar(response.message);
            setTimeout(() => 
              self.$router.push({
                name: 'student.evaluation',
              }),100)
          }).catch(error => {
            console.log('error',error)
            self.setShowSnackbar(error.response.data.message);
          })
          },1500);
        }
        function customAction() {
        if(case_item.question_type.section == "SPEAKING"){
          if(!window.tempState.mp3_onverted_response.ready) {
            setTimeout(customAction, 100);
            return;
          }
          const data = window.tempState.mp3_onverted_response.data;
          const file_path = window.tempState.mp3_onverted_response.file_path;
          self.form.file_path = file_path
          self.evaluationDetails = data
          self.PronunciationAssessment = data.NBest[0].PronunciationAssessment
       
          self.form.content_percentage = self.PronunciationAssessment.CompletenessScore
          self.form.fluency_percentage = self.PronunciationAssessment.FluencyScore
          self.form.pronunciation_percentage = self.PronunciationAssessment.PronScore
          self.form.form_percentage = self.PronunciationAssessment.AccuracyScore
          self.form.answer_sheet_details = {
            evaluation_details : [self.evaluationDetails], 
            content: null,
            form: null,
            grammar: null,
            fluency: null,
            pronunciation: null,
            spelling: null,
            discourse: null,
            vocabulary: null,
            matched_keywords: null,
            keyword_out_of_order: null,
            word_count: null,
            line_count: null,
            insertion_count: null,
            omission_count: null,
            replacement_count:null,
            pair_match_count:null,
            correct_answer_count:null,
            wrong_answer_count:null,
            development_structure_coherence:null,
            general_linguistics : null,
            question_option_id : null,
            question_hints_id : null,
            answer : "This is an answer for a test which is taken for a Read Aloud",
            comment : ""
          }      
          //console.log('test',self.PronunciationAssessment)
           self.form.user_id = localStorage.getItem('user_id');
          //this.form.question_test_id = '';
          
          self.form.test_id = self.test_id;
          self.form.question_id = case_item.id;
       
          self.SubmitactResultList(self.form)
          .then(response => {
            response.answer_sheet_id
            localStorage.setItem('answer_sheet_id', response.answer_sheet_id);
            localStorage.setItem('answer_bottom_hide', true);
            self.setShowSnackbar(response.message);
            setTimeout(() => 
              self.$router.push({
                name: 'student.evaluation',
                
              }),1000)
          }).catch(error => {
            self.setShowSnackbar(error.response.data.message);
          })
          self.convertMp3ToWav()
        } 
      }
          customAction();
      },
      convertMp3ToWav(uri, transcipt) {
        if(typeof uri === 'undefined' || uri === null) return;
        const audioContext = new AudioContext();
        xhr({
            uri: uri,
            responseType: 'arraybuffer'
        }, function (err, body, resp) {
            if (err) throw err
            // decode the MP3 into an AudioBuffer
            audioContext.decodeAudioData(resp, function (buffer) {
                // encode AudioBuffer to WAV
                let wav = toWav(buffer);
                let anchor = document.createElement('a')
                document.body.appendChild(anchor)
                anchor.style = 'display: none'
                let blob = new window.Blob([ new DataView(wav) ], {
                    type: 'audio/wav'
                })
                //this.$root.hddd
                //let url = window.URL.createObjectURL(blob)
              var reader = new FileReader();
              reader.readAsDataURL(blob);
               reader.onloadend =(() => {
                var base64String = reader.result;
                  var arr = base64String.split(","),
                  mime = arr[0].match(/:(.*?);/)[1],
                  bstr = atob(arr[1]),
                  n = bstr.length,
                  u8arr = new Uint8Array(n);
                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                  const filename = 'audio/'+'speaking.wav';
                  let file_path =baseUrl+'public-read-1/'+filename
                  //console.log("thisMahadi",file_path)
                  this.newDecodeFile = new File([u8arr], filename, { type: mime });
                  document.getElementById("loader").style.display = 'flex'
                  SpeechToText(this.newDecodeFile, transcipt).then((response) => {
                    
                    console.log('transcipt',transcipt);
                    if(response){
                     
                      
                      // let button = document.querySelector("#submit");
                      // if(button) button.disabled = false;
                      // button.style.pointerEvents = "inherit";
                      // button.style.cursor = "pointer";
                      
                      document.getElementById("loader").style.display = 'none'
                      
                       window.tempState = {
                        mp3_onverted_response: {
                           ready: true,
                           data: response,
                           file_path: file_path
                        },
                      };
                      this.sampleBody.answer_sheet_details.evaluation_details=response;
                      this.actSaveAnswerSheet(this.sampleBody)
                      .then(({ message }) => {
                        this.setShowSnackbar(message);
                        this.answer = ""
                      });
                    }else{
                      let displayText = document.querySelector(".displayText");
                      if(displayText) displayText.innerHTML = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';                  
                      // window.tempState = {
                      //    mp3_onverted_response: {
                      //      ready: false,
                      //      data: {"msg": "ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly."},
                      //      file_path: ''
                      //    }
                      //  }; 
                      document.getElementById("loader").style.display = 'none'
                   }
                });
                putObject(this.newDecodeFile, "public-read-1");
              });            
            })
        })
      },
      countDownTimer() {
        
          if(this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                //console.log('ff',this.countDown)
                this.countDownTimer()
            }, 1000)
          }
        
      },
      textGear(){                
        //textgrears api call
          this.textgearData = false
          axios.get(TextGearsAPI,{ params: { key: Keys ,text: this.$root.textGearvalue } }
          ).then(response => (
              this.evaluation_detail = response.data,
              this.textgearData = true,
              console.log('TextGear data',this.evaluation_detail)
          )).catch(err => {
              console.error('error',err);
          })
      }
    },
    mounted(){
      //localStorage.setItem('Slideindex', this.Slideindex)
      if(this.$root.currentQuestion){
        const indexStr = localStorage.getItem('Slideindex');
        var index = parseInt(indexStr)
        setTimeout(() => 
        console.log(this.current_slide_number,index),
        this.current_slide_number = index, 100);
      }
      if(this.$root.NextQuestionDetect){
        setTimeout(() => 
        this.current_slide_number = index+1, 100);
      }
        // button.disabled = true;
      this.test_id = localStorage.getItem('pte_id');
      this.actTestDetails(this.test_id)
        .then((res) => {
          this.pteDetails = res;
          //console.log('chad',res)
          this.question = res.questions;
          this.totalquestion = res.questions.length;
          this.filepath = res.questions[0].file_path
        });
      //this.$root.loading = true;
      this.layoutMeta = this.$route.meta.layout;  
      setTimeout(() => 
      document.getElementById('modal__ptePractice').classList.add("animation__modal"), 10 );
    },
    
    created() {
      if(this.CurrentTimeAudio == '00:01'){
        this.countDownTimer();
      }
    },
}
</script>
<style scoped>
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid rebeccapurple;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
#loader{
    z-index: 99999999999;
    position: fixed;
    background: rgb(255 255 255 / 89%);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.displayText{
 text-align: center;
 color: red;
}
</style>