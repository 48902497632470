<template>
    <div class="md-prograss__s">
        <div class="md-progress-bars">
        <div class="progress_1">
            <progress class="progress-bars" value="0" max="5" id="progressBar"></progress>
        </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'md-prograss__s',
  props:{
    preparation_time:{
       type: Number
    }
  },
  data () {
    return {
      width: '',
      barprograss: '',
      count : '',
      //mutabledata: 5
    }
  },
  //   computed: {
  //   status: {
  //     get() { return this.preparation_time },
  //     set(value) {
  //       this.preparation_time = value;
  //     }
  //   }
  // },
  methods: {
    countDownTimer() {
      if(this.preparation_time > 0) {
        setTimeout(() => {
            this.preparation_time -= 1
            // console.log('ddgg',this.countDown)
            document.getElementById("progressBar").value = 5 - this.preparation_time;
            this.countDownTimer()
        }, 1000)
      }
    }
  },
  // created(){
  //  this.time = this.preparation_time
  // },
 mounted(){
   // document.getElementById("progressBar").value = 10 - this.timer;
   this.countDownTimer()
  //  console.log("data",this.countDown)
   
   
 },
}
</script>
<style scoped>
.md-progress-bars{
    padding: 15px;
    border: 1px solid #bebebe;
    margin: 25px 0px;
    border-radius: 4px;
}
.progress-bars {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #7432b8;
    transition: width .6s ease;
    min-height: .6rem;
    width: 100%;
}
progress[value]::-webkit-progress-value {
  background-image:-webkit-linear-gradient(left, #7432b8, #7432b8);
  border-radius: 2px; 
  background-size: 35px 20px, 100% 100%, 100% 100%;
    
}
.progress_1 {
    display: -ms-flexbox;
    display: flex;
    height: .6rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}
</style>